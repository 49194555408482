'use client'

import Script from 'next/script'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

const CookieScript = () => {
  const pathname = usePathname()

  const showCookieBanner = !((pathname.includes('/dashboard') || pathname.includes('/menu') || pathname.includes('/booking')))

  useEffect(() => {
    if (showCookieBanner) {
      const observer = new MutationObserver(() => {
        const buttonContainer = document.querySelector('#cookiescript_buttons') as HTMLElement
        if (buttonContainer) {
          buttonContainer.style.flexDirection = 'row-reverse'
          observer.disconnect()
        }
      })

      observer.observe(document.body, {
        childList: true,
        subtree: true
      })

      return () => observer.disconnect()
    }
  }, [showCookieBanner])

  return showCookieBanner
    ? (
      <>
        <Script
          src='//cdn.cookie-script.com/s/7f7f202f78446ce3982e5c3ccec49c79.js'
          strategy='afterInteractive'
        />
        <style jsx global>{`
        #cookiescript_buttons {
          display: flex;
          flex-direction: row-reverse;
          font-weight: 700;
        }
        #cookiescript_buttons .btn {
          margin-left: 10px;
          margin-right: 0;
        }
      `}
        </style>
      </>
      )
    : null
}

export default CookieScript
