'use client'

import React, { Component, ReactNode } from 'react'
import axios from 'axios'
import { ClientError } from '@/lib/interfaces/misc'
import Image from 'next/image'

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static MAX_RELOAD_ATTEMPTS = 3

  constructor (props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (_: Error): ErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch (error: Error, errorInfo: React.ErrorInfo) {
    // Detectar si es un error de carga de chunk
    if (error.message.includes('Loading chunk')) {
      const reloadAttempts = parseInt(sessionStorage.getItem('reloadAttempts') || '0', 10)

      if (reloadAttempts < ErrorBoundary.MAX_RELOAD_ATTEMPTS) {
        sessionStorage.setItem('reloadAttempts', (reloadAttempts + 1).toString())
        console.warn('Error de carga de chunk detectado. Recargando la página...')
        window.location.reload()
      } else {
        console.error('Máximo de intentos de recarga alcanzado. No se recargará la página.')

        const errorData: ClientError = {
          message: error.message,
          stack: error.stack,
          componentStack: errorInfo.componentStack,
          userAgent: navigator.userAgent,
          url: window.location.href
        }

        // Reportar el error al backend después de exceder los intentos de recarga
        axios.post('/api/error-client', errorData)
          .then(() => console.log('Error reportado al backend'))
          .catch(err => {
            console.error('Fallo al reportar el error:', err)
          })
      }
    } else {
      // Para otros tipos de errores, reportarlos inmediatamente
      const errorData: ClientError = {
        message: error.message,
        stack: error.stack,
        componentStack: errorInfo.componentStack,
        userAgent: navigator.userAgent,
        url: window.location.href
      }

      // Reportar errores al backend
      axios.post('/api/error-client', errorData)
        .then(() => console.log('Error reportado al backend'))
        .catch(err => {
          console.error('Fallo al reportar el error:', err)
        })
    }
  }

  handleReload = () => {
    // Reiniciar contador de intentos de recarga
    sessionStorage.setItem('reloadAttempts', '0')
    window.location.reload()
  }

  render () {
    if (this.state.hasError) {
      return (
        <div style={styles.container}>
          <div style={styles.content}>
            <Image
              src='https://res.cloudinary.com/dt0hfjec6/image/upload/v1718433493/bm-logo_kkluxy.png'
              alt='Barmanager Logo'
              width={200}
              height={100}
              style={styles.logo}
            />
            <hr style={styles.separator} />
            <h1 style={styles.title}>Algo salió mal</h1>
            <p style={styles.message}>Lamentamos el inconveniente. Por favor, intenta recargar la página.</p>
            <button style={styles.button} onClick={this.handleReload}>
              Recargar página
            </button>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#f4f4f7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    boxSizing: 'border-box'
  },
  content: {
    width: '90%',
    maxWidth: '600px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '40px 20px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    boxSizing: 'border-box'
  },
  logo: {
    width: '60%',
    maxWidth: '200px',
    marginBottom: '20px',
    height: 'auto'
  },
  separator: {
    width: '80%',
    margin: '20px auto',
    border: '0',
    borderBottom: '1px solid #00000020'
  },
  title: {
    color: '#333333',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px'
  },
  message: {
    fontSize: '16px',
    color: '#51545e',
    lineHeight: '1.6',
    marginBottom: '30px'
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#ffffff',
    backgroundColor: '#4bd696',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease'
  }
}

export default ErrorBoundary
