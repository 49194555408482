// lib/firebase/firebaseClient.ts
import { Analytics, getAnalytics, isSupported } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore' // Importa Firestore

const firebaseConfig = {
  apiKey: 'AIzaSyBc2_u6WGwKVvoq51Wji3lZfuc13yfm1NU',
  authDomain: 'barmanager-trivify.firebaseapp.com',
  projectId: 'barmanager-trivify',
  storageBucket: 'barmanager-trivify.appspot.com',
  messagingSenderId: '811470643460',
  appId: '1:811470643460:web:18422f9feade02beb03051'
}

// Inicializar Firebase app
const app = initializeApp(firebaseConfig)

// Inicializar Auth
export const auth = getAuth(app)
auth.languageCode = 'es'

// Inicializar Firestore
export const fireStore = getFirestore(app) // Inicializa Firestore y lo exporta

// Inicializar Analytics sólo en el lado del cliente
export let analytics: Analytics
isSupported().then((isSupported) => {
  if (isSupported) {
    analytics = getAnalytics(app)
  }
})
